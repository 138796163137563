<template>
  <div>
    <el-main>
      <form-layout :column="optionChild.column" :dataForm="dataObj" ref="formLayout"></form-layout>
    </el-main>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";
import {
  update,
  add,
  getDictTree,
  getDictDetail
} from "@/api/system/dict";
import { optionChild } from "@/option/system/dict";
import {getDict} from "../../api/system/dict";
export default {
  components: { FormLayout, HeadLayout },
  computed: {
    ...mapGetters(["userInfo", "permission", "language"]),
  },
  props: {
    dataObj:{
      type: Object,
      default: () => {
        return {};
      },
    }
  },
  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
      {
        label: this.$t('cip.cmn.btn.saveBackBtn'),
        emit: "head-save-back",
        type: "button",
        icon: ""
      }, {
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: "head-cancel",
        type: "button",
        icon: ""
      }],
      id:'',
      parentId: '',
      optionChild,
    }
  },
  created() {
    this.optionChild = this.optionChild(this,this.language)
    this.initData()
    this.parentId = this.dataObj.parentId
    this.findObject(this.optionChild.column, "code").disabled = true;
    this.findObject(this.optionChild.column, "parentId").disabled = true;

    if(this.dataObj.id){
      getDictDetail(this.dataObj.id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    }
  },

  methods: {
    headSave(type) {
      this.$refs.formLayout.$refs.form.form.dictKey=this.$refs.formLayout.$refs.form.form.dictKey.trim()
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading()
          let obj = this.$refs.formLayout.dataForm
          obj.parentId = this.parentId
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then((res) => {
            if (type == 'back') {
              this.close()
            }
            this.dataObj=res.data.data
            this.getValue(this.dataObj.id)
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });
            this.$emit("cancel")
          } ,error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false
          }).finally(()=>{
            this.$loading().close()
            this.$emit("cancel")
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })
    },

    getValue(id) {
      getDict(id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    },
    initData() {
      getDictTree().then(res => {
        const column = this.findObject(this.optionChild.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

